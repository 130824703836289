<template>
  <div
    class="lawyerauth"
    style="height: 100%; box-size: border-box; padding-top: 1.173333rem"
  >
    <!-- 遮罩层 -->
    <van-overlay :show="loading">
      <div class="wrapper" @click.stop>
        <van-loading vertical color="#0094ff">加载中</van-loading>
      </div>
    </van-overlay>
    <!-- 选择律所弹出层 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="pickData"
        @change="chooseChangeFn"
        @confirm="finishChoose"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!--标题部分-->
    <fb-header slot="header" title="律师认证" fixed>
      <van-icon name="arrow-left" class="arrow" @click.stop="back" slot="left" />
      <div slot="right" class="right" @click="ocrImg">
        <span class="intro">拍摄律师证自动填写</span>
        <i class="iconfont icon-shot"></i>
      </div>
    </fb-header>
    <!--主体部分-->
    <div class="body">
      <!--切换tab页-->
      <van-tabs v-if="!sendData" type="card" animated v-model="activeName">
        <van-tab name="1" title="律师" lazy-render>
          <lawyerCertification
            v-if="activeName == 1"
            :lawImg="lawImg"
            :lawInfo="lawInfo"
            :showPickerData="showPicker"
            @showPick="showPick"
            @changeLoading="changeLoading"
            @success="isCertification"
            :pickValue="pickValue"
          />
        </van-tab>
        <van-tab name="2" title="法律工作者" lazy-render>
          <legalWorker
            v-if="activeName == 2"
            :lawImg="lawImg"
            :lawInfo="lawInfo"
            :showPickerData="showPicker"
            @showPick="showPick"
            @changeLoading="changeLoading"
            @success="isCertification"
            :pickValue="pickValue"
          />
        </van-tab>
        <van-tab name="3" title="其他" lazy-render>
          <other
            v-if="activeName == 3"
            :lawImg="lawImg"
            :lawInfo="lawInfo"
            :showPickerData="showPicker"
            @showPick="showPick"
            @changeLoading="changeLoading"
            @success="isCertification"
            :pickValue="pickValue"
          />
        </van-tab>
      </van-tabs>
      <div v-if="sendData">
        <lawyerCertification
          style="padding-top: 0px"
          v-if="activeName == 1"
          :sendData="sendData"
          :lawImg="lawImg"
          :lawInfo="lawInfo"
          :showPickerData="showPicker"
          @showPick="showPick"
          @changeLoading="changeLoading"
          :pickValue="pickValue"
        />
        <legalWorker
          style="padding-top: 0px"
          v-if="activeName == 2"
          :sendData="sendData"
          :lawImg="lawImg"
          :lawInfo="lawInfo"
          :showPickerData="showPicker"
          @showPick="showPick"
          @changeLoading="changeLoading"
          :pickValue="pickValue"
        />
        <other
          style="padding-top: 0px"
          v-if="activeName == 3"
          :sendData="sendData"
          :lawImg="lawImg"
          :lawInfo="lawInfo"
          :showPickerData="showPicker"
          @showPick="showPick"
          @changeLoading="changeLoading"
          :pickValue="pickValue"
        />
      </div>
    </div>
  </div>
</template>
<script>
import goBackMixin from '@mixins/goBack'
import { qiniuBase } from '~api-config'
import { mapState } from 'vuex'
import lawyerCertification from './components/lawyer.vue'
import legalWorker from './components/legal-worker.vue'
import other from './components/other.vue'
export default {
  name: 'ocr-Lawyer-Auth',
  mixins: [goBackMixin],
  components: {
    lawyerCertification,
    legalWorker,
    other,
  },
  data() {
    return {
      activeName: '1',
      sendData: undefined,
      loading: false,
      lawImg: '', // 律师图片
      lawInfo: {}, // 识别出来的 律师信息
      showPicker: false, // pick
      pickData: [], // pick data
      pickValue: '', // 选择的值
      // orc识别
      lawyerName: '',
      searchLawyerName: '',
      searchLawFirmName: '',
      jobNumber: '',
      zheng: '',
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    // 返回上一个页面
    back() {
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        history.back(-1)
      }
    },
    // 获取用户认证信息
    isCertification() {
      this.loading = true
      this.$axios
        .get(`${this.$base}/farbun/lawyer/lawyer_Info`)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 200) return this.$notify(res.message)
          if (!res.data) return
          // 认证了
          // 格式化数据 传递显示
          const obj = {
            name: res.data.lawyerName,
            lawyerName: res.data.lawyerName,
            licenseNumber: res.data.jobNumber,
            type: res.data.duty,
            lushiImg: res.data.workCardUrl,
            cardUrl: res.data.callingCardUrl,
          }
          if (res.data.province) {
            obj.location = `${res.data.province}/${res.data.city}/${res.data.county}`
          }
          if (res.data.lawFirmId) {
            obj.lawFirmId = res.data.lawFirmId
            obj.lawFirmName = res.data.lawFirmName
          }
          // 判断认证类型
          switch (res.data.jobType) {
            case '律师':
              this.activeName = '1'
              obj.jobType = '律师'
              break
            case '法律工作者':
              this.activeName = '2'
              obj.jobType = '法律工作者'
              break
            case '其他':
              obj.jobType = '其他'
              this.activeName = '3'
              break
          }
          this.sendData = obj
        })
        .catch((err) => {
          this.loading = false
          Promise.reject(err)
        })
    },
    // 上传识别图片
    ocrImg() {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(3)
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        // ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          this.loading = true
          const f = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            f,
            {
              next: (file) => {},
              complete: (res, file) => {},
              error: () => {
                this.loading = false
                this.$toast('上传失败，请重新选择')
              },
            },
            (fileopt, key, file) => {
              const url = qiniuBase + '/' + key
              document.getElementById('file').remove()
              this.loading = false
              this.lawImg = url // 赋值 律师图片地址
              if (this.activeName === '1') {
                this.getInfoFromImg(url)
              }
            },
            true
          )
        }
        ipt.click()
      }
    },
    // 调用OCR识别文字
    async getOCR(url) {
      try {
        const res = await this.$axios.post(
          `${this.$base}/management/accurate`,
          'imgStr=' + url,
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )
        return res
      } catch (error) {}
    },
    // eslint-disable-next-line camelcase
    Trim(str, is_global) {
      var result
      result = str.replace(/(^\s+)|(\s+$)/g, '')
      if (is_global.toLowerCase() === 'g') {
        result = result.replace(/\s/g, '')
      }
      return result
    },
    // 识别图片内容填充
    async getInfoFromImg(val) {
      this.lawyerName = ''
      this.suoname = ''
      this.zheng = ''
      if (!val) return
      let url = val
      if (url.slice(0, 4) !== 'http') {
        if (window.location.href.slice(0, 5) === 'https') {
          url = `https:${url}`
        } else {
          url = `http:${url}`
        }
      }
      try {
        this.loading = true
        const { data } = await this.getOCR(url)
        this.loading = false
        if (data.code === 200 && data.data) {
          // eslint-disable-next-line camelcase
          const { words_result } = data.data
          // console.log(words_result)
          if (this.activeName !== '1') {
            const flag = words_result.some((v) => {
              if (this.activeName === '2') {
                return v.words.includes('工作者')
              } else if (this.activeName === '3') {
                return v.words.includes('法官') || v.words.includes('检察')
              }
            })
            if (!flag) {
              this.lawyerName = ''
              this.searchLawyerName = ''
              this.searchLawFirmName = ''
              this.jobNumber = ''
              const msg =
                this.activeName === '2'
                  ? '未识别到正确的法律工作者证，请重新拍摄上传'
                  : '未识别到正确的证件照，请重新拍摄上传'
              this.$notify(msg)
              this.lawImg = ''
              return
            }
          }
          // 执业证号：截取取第一个17位数字的字符串部分。
          const reg = /^[-+]?(([0-9]+)([.]([0-9]+))?|([.]([0-9]+))?)$/
          words_result.forEach((ele) => {
            if (this.zheng) return
            if (ele.words.length === 17) {
              // 判断是否都为数字
              const newStr = ele.words.trim()
              if (newStr.length === 17 && reg.test(newStr)) {
                this.zheng = newStr
              }
            } else if (ele.words.length >= 17) {
              const num = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
              let cardnum = ''
              for (let index = 0; index < ele.words.length; index++) {
                if (cardnum.length === 17) break
                const ele2 = ele.words[index]
                if (num.indexOf(ele2) >= 0) {
                  cardnum = ele.words.substr(index, 17)
                  break
                }
              }
              if (cardnum.length === 17 && reg.test(cardnum) && !this.zheng) {
                this.zheng = cardnum
              } else {
                this.zheng = ''
              }
            }
          })
          // 获取 名称
          let isok = false
          const reg2 = /^[\u4e00-\u9fa5]+$/
          words_result.forEach((ele, index) => {
            if (!isok) {
              ele.words = this.Trim(ele.words, 'g')
              if (ele.words.indexOf('持证人') >= 0) {
                const str = ele.words
                const newStr = str.replace('持证人', '')
                if (newStr.length >= 2) {
                  this.lawyerName = newStr.words
                  isok = true
                } else {
                  if (reg2.test(words_result[index + 1].words)) {
                    this.lawyerName = words_result[index + 1].words
                    isok = true
                  } else {
                    if (reg2.test(words_result[index - 1].words)) {
                      this.lawyerName = words_result[index - 1].words
                      isok = true
                    } else {
                      this.lawyerName = ''
                    }
                  }
                }
              } else {
                this.lawyerName = ''
              }
            }
          })
          // 获取 律所名称
          let s1 = ''
          let sp = -1
          let font = -1
          words_result.forEach((ele) => {
            if (ele.words.indexOf('律所') >= 0) {
              if (ele.words.indexOf(' ') >= 0) {
                // eslint-disable-next-line for-direction
                for (let index = ele.words.length; index < 1; index--) {
                  if (ele.words[index] === ' ' && sp === -1) {
                    sp = index
                  }
                  if (ele.words[index] === '律所' && font === -1) {
                    font = index
                  }
                }
                if (sp !== -1 && font !== -1) {
                  s1 = ele.words.substring(sp, font)
                  s1.replace('执业机构', '')
                  this.suoname = s1
                }
              }
            }
          })
          // 识别的数据
          this.lawInfo = {
            lawyerName: this.lawyerName,
            lawFirm: this.suoname,
            licenseNumber: this.zheng,
          }
          window.console.log('this.lawInfo', this.lawInfo)
        } else {
          this.$notify(data.message)
        }
      } catch (err) {
        this.$notify('识别失败,请重新上传')
        Promise.reject(err)
      }
    },
    // 展示pick
    showPick(pickData) {
      if (!pickData?.length) return
      this.pickData = pickData
      this.showPicker = true
    },
    finishChoose(val) {
      // window.console.log('finish data', val)
      this.pickValue = val
      this.showPicker = false
    },
    // pick change
    chooseChangeFn(picker, values) {
      this.pickValue = values
    },
    // 改变loading 状态
    changeLoading(data) {
      this.loading = data
    },
  },
  created() {
    this.isCertification()
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
  },
}
</script>

<style lang="stylus" scoped>
/deep/ .van-overlay
  z-index 999
  .wrapper
    height 100%
    display flex
    justify-content center
    align-items center
.lawyerauth
  background: rgb(247, 248, 250)
.head
  display flex
  justify-content space-between
  align-items center
  border-bottom-color green
  .left
    font-size 0.8rem
    font-weight 900
    display flex
    align-items center
    .arrow
      font-size 15px
      padding-left 0.2rem
.right
  margin-right 0.2rem
  .intro
    font-size 12px
    color #CCCCCC
    margin-right 0.5rem
/deep/ .fb-header-title
  text-align left
.body
  min-height: 100%;
  background-color #fff
  padding 10px
/deep/ .van-tabs__nav
  margin 0
/deep/ .van-tab
  border-color #00a2fc
  color #00a2fc
  transition all .3s
/deep/ .van-tabs__nav--card
  border-color #00a2fc
/deep/ .van-tab--active
  background-color #00a2fc !important
</style>
