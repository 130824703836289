<template>
  <!-- 律师认证 -->
  <div id="lawyer-certification">
    <van-form @submit="onSubmit">
      <!-- 姓名 -->
      <van-field v-model="ruleForm.lawyerName" name="姓名" label="姓名" placeholder="姓名" :rules="rules.name" clearable @blur="validateLicenseNumber" />
      <!-- 律所名称 -->
      <van-field v-model="ruleForm.lawFirm" name="律所名称" label="律所名称" placeholder="律所名称" :rules="rules.lawFirm" clearable @blur="searchlawFirmFn" />
      <!-- 执业证号 -->
      <van-field v-model="ruleForm.licenseNumber" name="执业证号" label="执业证号" placeholder="执业证号" :rules="rules.licenseNumber" clearable @blur="validateLicenseNumber" />
      <!-- 上传名片 -->
      <div class="upload_file">
        <span>上传名片</span>
        <uploadCard :sendcardUrl="ruleForm.callingCardUrl" @cardUrl="(val) => (ruleForm.callingCardUrl = val)" />
      </div>
      <!-- 按钮操作部分 -->
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit" :disabled="isEabel" :loading="btnLoading">提交</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { pydataBase } from '@api-config'
import uploadCard from './uploadCard.vue'
export default {
  name: 'lawyer-certification',
  components: { uploadCard },
  props: ['sendData', 'lawImg', 'lawInfo', 'pickValue', 'showPickerData'],
  data() {
    return {
      ruleForm: {
        lawyerName: '',
        lawFirmId: '',
        lawFirm: '',
        workCardUrl: '', // 律师证图片地址
        callingCardUrl: '', // 名片地址
        licenseNumber: '',
        jobType: '律师',
        isPresentDSJ: 0,
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'onBlur' },
          {
            validator: (val) => {
              return val.length >= 2 && val.length <= 20
            },
            trigger: 'onBlur',
            message: '长度在 2 到 20 个字符',
          },
        ],
        lawFirm: [
          {
            required: true,
            message: '请填写律所',
            trigger: 'blur',
          },
        ],
        licenseNumber: [{ required: false, message: '请填写执业证号', trigger: 'blur' }],
      },
      tipMessage: '', // 校验执业证号提示文本
      lushiImg: '',
      cardUrl: '',
      options: [],
      isEabel: true,
      btnLoading: false, // btn loading
    }
  },
  methods: {
    // 搜索 匹配 律所
    async searchlawFirmFn() {
      if (!this.ruleForm.lawFirm) return
      // if (this.options.length) {
      //   window.console.log(this.ruleForm.lawFirm)
      //   window.console.log(this.options[0].label)
      //   if (this.ruleForm.lawFirm === this.options[0].label) return
      // }
      let flag = true
      this.options.forEach((ele) => {
        if (ele.label === this.ruleForm.lawFirm) {
          flag = false
        }
      })
      if (!flag) return
      this.ruleForm.lawFirmId = ''
      this.$emit('changeLoading', true)
      const data = await this.getLawFirm()
      this.$emit('changeLoading', false)
      if (!this.ruleForm.workCardUrl) {
        if (!data.length) {
          this.$notify('未查询到律所,请上传证件照')
          this.isEabel = true
          this.ruleForm.lawFirmId = ''
          // this.ruleForm.lawFirm = ''
          return
        }
      }
      // 查询律所列表
      this.$emit('showPick', data)
    },
    // 弹出层 选择的 律所 改变
    chooseLawFirmChangeFn() {
      // console.log('choose law change')
    },
    // 获取律所列表
    async getLawFirm() {
      const { data: res } = await this.$axios.get(`${this.$base}/lts/Lawfirm/lawfirmlist`, {
        params: { name: this.ruleForm.lawFirm },
      })
      if (res.code !== '200') return this.$notify(res.message)
      this.options = []
      const data = []
      res.data.lawFirms.forEach((ele) => {
        this.options.push({ value: ele.id, label: ele.name })
        data.push(ele.name)
      })
      return data
    },
    // 校验执业证号
    async validateLicenseNumber() {
      if (this.ruleForm.workCardUrl) {
        return (this.isEabel = false)
      }
      if (!this.ruleForm.licenseNumber) return
      // if (this.lushiImg) {
      //   return (this.isEabel = false)
      // }
      // 校验
      this.$toast.loading('验证中...')
      const { data: res } = await this.$axios.post(`${pydataBase}/search/lawyer`, {
        account_code: this.ruleForm.licenseNumber,
      })
      if (res.code !== 200) {
        this.$notify(res.message)
        this.isEabel = true
        return this.$toast.clear()
      }
      res.data.data = res.data
      if (!res.data.data) {
        this.$notify('未查询到律师证信息,请上传律师证图片')
        this.isEabel = true
        return this.$toast.clear()
      }
      // 查询成功
      // 名称
      if (this.ruleForm.lawyerName !== res.data.data.name) {
        this.$notify('填写的姓名与律师证信息不符,请上传律师证图片')
        this.isEabel = true
        return this.$toast.clear()
      }
      // 律所
      if (this.ruleForm.lawFirm !== res.data.data.dept_name) {
        this.isEabel = true
        this.$notify('填写的律所与律师证不符合,请上传律师证图片')
      } else {
        this.isEabel = false
      }
      this.$toast.clear()
    },
    // 提交表单
    onSubmit() {
      this.btnLoading = true
      if (!this.ruleForm.lawFirmId) delete this.ruleForm.lawFirmId
      if (!this.ruleForm.workCardUrl || this.ruleForm.callingCardUrl) {
        this.ruleForm.isPresentDSJ = 1
      }
      this.$axios
        .post(`${this.$base}/farbun/lawyer/saveLawyer`, {
          ...this.ruleForm,
        })
        .then(({ data: res }) => {
          // console.log(res)
          if (res.code !== 200) return this.$notify(res.message)
          // 认证成功
          this.$notify({ type: 'success', message: res.message })
          this.$emit('success')
        })
      this.btnLoading = false
    },
  },
  watch: {
    // 监控 orc识别的律师 信息
    lawInfo: {
      handler(val) {
        if (!val) return
        const newVal = JSON.stringify(val)
        const newJson = JSON.parse(newVal)
        this.ruleForm.lawyerName = newJson.lawyerName || this.ruleForm.name
        this.ruleForm.lawFirm = newJson.lawFirm || this.ruleForm.lawFirm
        this.ruleForm.licenseNumber = newJson.licenseNumber || this.ruleForm.licenseNumber
      },
      immediate: true,
      deep: true,
    },
    pickValue: {
      handler(val) {
        if (!val) return
        // 循环 查询到的律师列表 匹配
        this.options.forEach((ele) => {
          if (ele.label === val) {
            this.ruleForm.lawFirm = ele.label
            this.ruleForm.lawFirmId = ele.value
          }
        })
      },
      immediate: true,
      deep: true,
    },
    showPickerData(val) {
      if (!val) {
        this.validateLicenseNumber()
      }
    },
    lawImg(val) {
      this.ruleForm.workCardUrl = val
      this.validateLicenseNumber()
    },
    sendData: {
      handler(val) {
        if (!val) return
        const newVal = JSON.stringify(val)
        const newJson = JSON.parse(newVal)
        // window.console.log('newJson', newJson)
        if (newJson.jobType !== '律师') return
        this.ruleForm = {
          lawyerName: newJson.lawyerName,
          lawFirmId: newJson.lawFirmId,
          lawFirm: newJson.lawFirmName,
          workCardUrl: newJson.lushiImg,
          callingCardUrl: newJson.cardUrl,
          licenseNumber: newJson.licenseNumber,
        }
        // window.console.log('this.ruleForm', this.ruleForm)
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
<style lang="stylus" scoped>
#lawyer-certification
  padding-top 20px
.icon
  cursor pointer
  width 20px
  height 20px
.lushiImg
  width 300px
  vertical-align bottom
  // height 200px
.bottom_tip
  width: 100%
  text-align center
  color rgb(255, 153, 132)
.upload_file
  padding 10px 16px
  display flex
  font-size 14px
  color: #646566;
  align-items: center;
  span
    display inline-block
    width 100px
</style>
