var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "lawyer-certification" } },
    [
      _c(
        "van-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c("van-field", {
            attrs: {
              name: "姓名",
              label: "姓名",
              placeholder: "姓名",
              rules: _vm.rules.name,
              clearable: "",
            },
            on: { blur: _vm.validateLicenseNumber },
            model: {
              value: _vm.ruleForm.lawyerName,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "lawyerName", $$v)
              },
              expression: "ruleForm.lawyerName",
            },
          }),
          _c("van-field", {
            attrs: {
              name: "律所名称",
              label: "律所名称",
              placeholder: "律所名称",
              rules: _vm.rules.lawFirm,
              clearable: "",
            },
            on: { blur: _vm.searchlawFirmFn },
            model: {
              value: _vm.ruleForm.lawFirm,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "lawFirm", $$v)
              },
              expression: "ruleForm.lawFirm",
            },
          }),
          _c("van-field", {
            attrs: {
              name: "执业证号",
              label: "执业证号",
              placeholder: "执业证号",
              rules: _vm.rules.licenseNumber,
              clearable: "",
            },
            on: { blur: _vm.validateLicenseNumber },
            model: {
              value: _vm.ruleForm.licenseNumber,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "licenseNumber", $$v)
              },
              expression: "ruleForm.licenseNumber",
            },
          }),
          _c(
            "div",
            { staticClass: "upload_file" },
            [
              _c("span", [_vm._v("上传名片")]),
              _c("uploadCard", {
                attrs: { sendcardUrl: _vm.ruleForm.callingCardUrl },
                on: { cardUrl: (val) => (_vm.ruleForm.callingCardUrl = val) },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "16px" } },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    round: "",
                    block: "",
                    type: "info",
                    "native-type": "submit",
                    disabled: _vm.isEabel,
                    loading: _vm.btnLoading,
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }