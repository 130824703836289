var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "uploadDiv", on: { click: _vm.chooseFile } }, [
      !_vm.cardUrl && !_vm.sendcardUrl
        ? _c("span", { staticClass: "uploadCard" }, [
            _c("div", [_vm._v("点我上传")]),
          ])
        : _c("img", {
            staticClass: "cardImg",
            attrs: { src: _vm.cardUrl || _vm.sendcardUrl },
          }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }