var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "lawyerauth",
      staticStyle: {
        height: "100%",
        "box-size": "border-box",
        "padding-top": "1.173333rem",
      },
    },
    [
      _c("van-overlay", { attrs: { show: _vm.loading } }, [
        _c(
          "div",
          {
            staticClass: "wrapper",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c("van-loading", { attrs: { vertical: "", color: "#0094ff" } }, [
              _vm._v("加载中"),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPicker,
            callback: function ($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker",
          },
        },
        [
          _c("van-picker", {
            attrs: { "show-toolbar": "", columns: _vm.pickData },
            on: {
              change: _vm.chooseChangeFn,
              confirm: _vm.finishChoose,
              cancel: function ($event) {
                _vm.showPicker = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "律师认证", fixed: "" },
          slot: "header",
        },
        [
          _c("van-icon", {
            staticClass: "arrow",
            attrs: { slot: "left", name: "arrow-left" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.back.apply(null, arguments)
              },
            },
            slot: "left",
          }),
          _c(
            "div",
            {
              staticClass: "right",
              attrs: { slot: "right" },
              on: { click: _vm.ocrImg },
              slot: "right",
            },
            [
              _c("span", { staticClass: "intro" }, [
                _vm._v("拍摄律师证自动填写"),
              ]),
              _c("i", { staticClass: "iconfont icon-shot" }),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "body" },
        [
          !_vm.sendData
            ? _c(
                "van-tabs",
                {
                  attrs: { type: "card", animated: "" },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "van-tab",
                    { attrs: { name: "1", title: "律师", "lazy-render": "" } },
                    [
                      _vm.activeName == 1
                        ? _c("lawyerCertification", {
                            attrs: {
                              lawImg: _vm.lawImg,
                              lawInfo: _vm.lawInfo,
                              showPickerData: _vm.showPicker,
                              pickValue: _vm.pickValue,
                            },
                            on: {
                              showPick: _vm.showPick,
                              changeLoading: _vm.changeLoading,
                              success: _vm.isCertification,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "van-tab",
                    {
                      attrs: {
                        name: "2",
                        title: "法律工作者",
                        "lazy-render": "",
                      },
                    },
                    [
                      _vm.activeName == 2
                        ? _c("legalWorker", {
                            attrs: {
                              lawImg: _vm.lawImg,
                              lawInfo: _vm.lawInfo,
                              showPickerData: _vm.showPicker,
                              pickValue: _vm.pickValue,
                            },
                            on: {
                              showPick: _vm.showPick,
                              changeLoading: _vm.changeLoading,
                              success: _vm.isCertification,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "van-tab",
                    { attrs: { name: "3", title: "其他", "lazy-render": "" } },
                    [
                      _vm.activeName == 3
                        ? _c("other", {
                            attrs: {
                              lawImg: _vm.lawImg,
                              lawInfo: _vm.lawInfo,
                              showPickerData: _vm.showPicker,
                              pickValue: _vm.pickValue,
                            },
                            on: {
                              showPick: _vm.showPick,
                              changeLoading: _vm.changeLoading,
                              success: _vm.isCertification,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.sendData
            ? _c(
                "div",
                [
                  _vm.activeName == 1
                    ? _c("lawyerCertification", {
                        staticStyle: { "padding-top": "0px" },
                        attrs: {
                          sendData: _vm.sendData,
                          lawImg: _vm.lawImg,
                          lawInfo: _vm.lawInfo,
                          showPickerData: _vm.showPicker,
                          pickValue: _vm.pickValue,
                        },
                        on: {
                          showPick: _vm.showPick,
                          changeLoading: _vm.changeLoading,
                        },
                      })
                    : _vm._e(),
                  _vm.activeName == 2
                    ? _c("legalWorker", {
                        staticStyle: { "padding-top": "0px" },
                        attrs: {
                          sendData: _vm.sendData,
                          lawImg: _vm.lawImg,
                          lawInfo: _vm.lawInfo,
                          showPickerData: _vm.showPicker,
                          pickValue: _vm.pickValue,
                        },
                        on: {
                          showPick: _vm.showPick,
                          changeLoading: _vm.changeLoading,
                        },
                      })
                    : _vm._e(),
                  _vm.activeName == 3
                    ? _c("other", {
                        staticStyle: { "padding-top": "0px" },
                        attrs: {
                          sendData: _vm.sendData,
                          lawImg: _vm.lawImg,
                          lawInfo: _vm.lawInfo,
                          showPickerData: _vm.showPicker,
                          pickValue: _vm.pickValue,
                        },
                        on: {
                          showPick: _vm.showPick,
                          changeLoading: _vm.changeLoading,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }