<template>
  <div>
    <div class="uploadDiv" @click="chooseFile">
      <span v-if="!cardUrl && !sendcardUrl" class="uploadCard">
        <div>点我上传</div>
      </span>
      <img class="cardImg" v-else :src="cardUrl || sendcardUrl" />
    </div>
  </div>
</template>
<script>
import { qiniuBase } from '~api-config'
export default {
  name: 'upload-card',
  props: ['sendcardUrl'],
  data() {
    return {
      cardUrl: '',
    }
  },
  methods: {
    chooseFile() {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(3)
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        // ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          const f = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            f,
            {
              next: (file) => {},
              complete: (res, file) => {},
              error: () => {
                this.$toast('上传失败，请重新选择')
              },
            },
            (fileopt, key, file) => {
              const url = qiniuBase + '/' + key
              document.getElementById('file').remove()
              this.$emit('cardUrl', url)
              this.cardUrl = url
            },
            true
          )
        }
        ipt.click()
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.uploadDiv
  width 50vw
.uploadCard
  display flex
  justify-content center
  align-items center
  background-color: #fff;
  border: 1px dashed #d9d9d9
  border-radius: 6px
  box-sizing: border-box
  width: 100%
  min-height 100px
  text-align: center
  cursor: pointer
  position: relative
  overflow: hidden
  transition all .3s
  .el-icon-upload
    font-size: 67px;
    color: #c0c4cc;
    margin: 40px 0 16px;
    line-height: 50px;
  div
    color #606266
.cardImg
  width 100%
</style>
